<script setup lang="ts">
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';

const rawNavigationElements = useNavigation({
  type: 'footer-navigation',
}).navigationElements;

const navigationElements = computed(() => rawNavigationElements.value?.filter((navigationElement) => navigationElement.name !== 'legal') || []);

const legalNavigationElements = computed(() => rawNavigationElements.value?.find((navigationElement) => getTranslatedProperty(navigationElement, 'name')?.toLowerCase() === 'legal')?.children || []);

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
// const sendNewsletter = () => alert('TODO this');

const newsletterform = ref({
  email: '',
  validationError: '',
  showValidationError: false,
  showSuccess: false,
  successMessage: '',
});

async function sendNewsletter(e) {
  console.log(e);
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newsletterform.value.email)) {
    // Form submission will occur through the specified action  URL.
    newsletterform.value.showValidationError = false;
    newsletterform.value.validationError = ''; // Clear any previous error message

    await $fetch(e.target.action, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      body: newsletterform.value,
    })
      .then((result) => {
        console.log(result);
        newsletterform.value.showSuccess = true;
      })
      .catch((error) => {
        console.error('Contact form could not be send', error);
        newsletterform.value.validationError = 'Leider ist ein Fehler aufgetreten. Versuche es später nochmal.';
        newsletterform.value.showValidationError = true;
      });
  } else {
    newsletterform.value.validationError = 'Bitte gib eine gültige E-Mail ein';
    newsletterform.value.showValidationError = true;
  }
}

const paymentProviders = ['visa', 'master-card', 'amex', 'klarna', 'paypal'];
</script>
<template>
  <footer>
    <menu class="bg-dark text-light">
      <div class="flex flex-col justify-center lg:px-15 mx-auto px-5">
        <div class="py-20 w-full grid grid-flow-row-dense grid-cols-1 xl:grid-rows-none xl:grid-cols-6 gap-y-6 md:gap-x-6">
          <div class="flex flex-col footer-nav-col gap-4 md:col-span-2 max-w-[400px]">
            <NuxtLink :to="formatLink(`/`)">
              <span class="sr-only">Shopware</span>
              <img v-lazy-load  class="h-6 w-auto sm:h-6" width="118" height="21" data-src="/logo/sn_logo_text_weiss.svg" alt="logo of the shop" />
            </NuxtLink>
            <p>Melde Dich für unseren Newsletter an und erhalte 10% Rabatt auf Deine erste Bestellung.</p>
            <!-- <div class="success-message" v-if="newsletterform.showSuccess">Bitte prüfe deinen Posteingang und bestätige deine Anmeldung.</div>
            <div v-else class="signup-form">
              <div class="relative">
                <form @submit.prevent="sendNewsletter" action="https://sn-supernatural.us3.list-manage.com/subscribe/post?u=8c566562c954a28011297a164&id=5654e21fd0&f_id=003e47e2f0">
                  <input class="p-4 w-full bg-dark-grey text-grey" type="email" placeholder="Deine E-Mail Adresse" v-model="newsletterform.email" required />
                  <button class="absolute right-0 h-full px-5 bg-dark border-1 border-white uppercase" @click="sendNewsletter()">abschicken</button>
                </form>
              </div>
              <div class="error-message text-red mt-2" v-if="newsletterform.showValidationError">
                {{ newsletterform.validationError }}
              </div>
            </div> -->
            <div id="mc_embed_shell">
              <div id="mc_embed_signup" class="signup-form">
                <form action="https://sn-supernatural.us3.list-manage.com/subscribe/post?u=8c566562c954a28011297a164&amp;id=5654e21fd0&amp;f_id=003e47e2f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self">
                  <div id="mc_embed_signup_scroll" class="relative flex">
                    <div class="mc-field-group grow w-full">
                      <label for="mce-EMAIL" class="sr-only">Email Adresse</label>
                      <input type="email" name="EMAIL" class="required w-full email p-4 grow bg-dark-grey text-grey rounded-none" id="mce-EMAIL" required value="" pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" placeholder="Deine E-Mail Adresse" />
                    </div>
                    <div aria-hidden="true" style="position: absolute; left: -5000px"><input type="text" name="b_8c566562c954a28011297a164_5654e21fd0" tabindex="-1" value="" /></div>
                    <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button right-0 h-full px-5 py-4 bg-dark border-1 border-white uppercase rounded-none" value="Abschicken" />
                  </div>
                  <div id="mce-responses" class="clear">
                    <div class="response" id="mce-error-response" style="display: none"></div>
                    <div class="response" id="mce-success-response" style="display: none"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="col-span-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-none xl:grid-flow-col gap-10 md:gap-8 xl:divide-x">
            <NavigationElement class="md:pl-10" :element="navigationElement" v-for="navigationElement in navigationElements?.slice(0, -2)" />
            <div class="flex flex-col footer-nav-col-last gap-10 md:pl-10">
              <NavigationElement :element="navigationElement" v-for="navigationElement in navigationElements?.slice(-2)" />
              <!-- <div class="flex gap-4">
                <p class="text-grey">Sprache</p>
                <LanguageSwitcher />
              </div> -->
            </div>
          </div>
        </div>
        <p class="text-grey pb-3">Alle Preise inkl. der gesetzl. MwSt. zzgl. Versandkosten und ggf. Nachnahmegebühren, wenn nicht anders angegeben.</p>
      </div>
      <div class="bg-dark-grey px-5 lg:px-15">
        <div class="mx-auto py-4 flex flex-col xl:flex-row justify-between text-grey gap-8">
          <div class="flex flex-col xl:flex-row md:divide-x divide-gray-500 gap-0 lg:items-center">
            <p class="pr-4">© {{ new Date().getFullYear() }} super.natural Europe GmbH</p>
            <div class="flex flex-col md:flex-row md:divide-x divide-gray-500 lg:items-center gap-2 lg:gap-0 mt-2 lg:mt-0">
              <NavigationLink :category="legalNavigationElement" v-for="legalNavigationElement in legalNavigationElements" :key="legalNavigationElement.id" class="md:px-4 py-2" />
            </div>
          </div>
          <div class="flex flex-wrap justify-between gap-2">
            <NuxtImg class="w-auto h-6" v-for="paymentProvider in paymentProviders" :src="`/icons/${paymentProvider}.svg`" 
            :alt="paymentProvider"
            width="34"
            height="21" 
            />
          </div>
        </div>
      </div>
    </menu>
  </footer>
</template>
